import React from "react"
import styled from "styled-components"
import PageWrapper from "../components/PageWrapper"
import underlineImg from "../images/landing/underline-scribble.svg"
const About = () => {
  return (
    <PageWrapper>
      <Header>
        <h1>About us</h1>
        <p>
          Learn more about us
          <span />
        </p>
      </Header>
      <Content>
        <p className="italic small">Your life is made up of stories</p>
        <p>
          Build My Book is a professional album design service. So many people
          simply do not have the time to put together a layout for a printed
          photo book so their photos never see the light of day. Time passes and
          the memory of that special occasion such as a wedding, new baby or
          travel holiday fades. Your special memories are forgotten on your
          devices or social media accounts. Which is why when it comes to
          reliving a special occasion nothing beats print. A beautifully printed
          and bound coffee table book allows you to enjoying a holiday or your
          wedding all over again.
        </p>
        <p>
          Build My Book was inspired by the need to share, relive and enjoy the
          special moments of your life without the work of creating your own
          photo book. Because lets face it, who has the time to build page
          styles, edit photos and fiddle around with designs ? Now you can
          simply send your photos to our team of professional album designers
          who have years of local and international experience.
        </p>
        <p>
          Our designers are trained in professional photography, printing and
          graphic design. They will select, curate and edit your photos into a
          beautiful collection of special moments. With years of professional
          and commercial experience behind them, the design team will then
          layout your photo collection into a beautifully styled photo book for
          your coffee table. The photo book will be designed in a style of
          simple elegance that does not date. will be timeless which will show
          off your photos to best effect for maximum viewing enjoyment.
        </p>
        <p>
          Our books are printed by Q-Photo an award-winning printing company. We
          offer two types of photo books, a genuine leather cover or a printed
          hard cover.
        </p>

        <p>
          Q-Photo sources and selects only the finest papers from all over the
          world to make sure that your special memories are printed world-class
          materials.
        </p>
        <p>Q-Photo offers a money-back quality guarantee on all photo books.</p>
        <p>
          Printed Hard Covers are made with a photo design on the front and back
          of the cover which has a Matt finish. These beautiful covers are
          printed on 11 ink Epson printers for amazing depth of colour and
          vibrancy.
        </p>
        <p>
          Genuine Leather Covers are made from genuine Napa leather available in
          3 colours Oxblood, Black or Brown. You can accessorize the cover with
          beautiful embossing from 8mm to 12mm high in our standard font style.
        </p>
        <p>
          Q-Photo prints standard photo books on an HP Indigo press, which means
          your photos look amazing thanks to HP’s exclusive ElectoInk liquid
          ink. The standard photo book is bound with the world renowned PUR glue
          method from Belgium for top quality binding that lasts.
        </p>
        <p>
          The layflat photo book is printed on genuine Fuji Crystal Archive
          photographic paper and when it opens the pages lay flat across the
          middle. An open layflat photo book looks like one picture there is no
          bend in middle. If kept out of direct sunlight the colour in the Fuji
          Crystal Archive photographic paper of a layflat photo book will last
          over 100 years.
        </p>
        <p>
          The flush mounted pages are created by mounting all the pages of a
          layflat album onto a 0,1 cm board which shows the print off to
          perfection. The pages can never bend. This is the choice of
          professionals.
        </p>
      </Content>
      {/* <Content>
        <p>
          Q-Photo started out in Sunnyside Pretoria in 1984 as a small silver
          halide photographic printing company called Express Photo which
          provided fast, top quality photo prints.
        </p>
        <p className="italic small">
          Capturing and sharing special moments has been the heart of the
          business, it’s all about providing you with beautiful ways of making
          the moment last.
        </p>
        <p>
          The company grew into the biggest independent photographic printing
          company in South Africa with a retail chain of one hour photo print
          shops that delivered award-winning photographic printing services.
          This was before the age of the internet and cell phones. Technological
          developments fundamentally changed the way we capture and share
          photos, a challenge which Q-Photo met by embracing change and
          innovation.
        </p>
        <p>
          We expanded our printing equipment collection to include HP Indigo and
          Epson printers which enabled us to innovate and expand on our printing
          products beyond photo prints by introducing photo books, printed
          canvas and photo gifts.
        </p>
        <p>
          The company was renamed Q-Photo, the “Q” in our name is for “Quality”
          and we have a proud tradition of delivering beautifully made printing
          products that celebrate your special memories. Q-Photo changed from a
          retail chain to an e-commerce platform that now receives orders
          throughout South Africa and surrounding countries. Our products are a
          combination of skilled craftsmanship and state-of-the-art printing
          technologies with materials and equipment sourced from all of the
          world.
        </p>
        <p>
          Through the regular annual attendance at international photographic
          and printing expos such as PMA and WPPI (United States) and Photokina
          (Germany) we ensure that we stay up-to date with the latest
          international printing trends.
        </p>
        <p className="italic">
          We love what we do, creating bespoke printing products is of the
          utmost importance to us from sourcing the materials, to printing on
          state-of-the-art equipment, to packaging the final product, we pay
          attention to the whole journey of creating really beautiful products
          that we really care about.
        </p>
        <p>
          Today Q-Photo is South Africa’s premier online printing company, with
          the widest range of products on offer which will enable you to tell
          your story and share your special memories. Make the moment last with
          beautiful photo books, relive the memories with our stunning home
          décor or give the best gift ever – your shared memories.
        </p>
        <p className="bold">
          We look forward to helping you make your special moments last.
        </p>
      </Content> */}
    </PageWrapper>
  )
}

const Header = styled.div`
  margin: 50px 0;
  text-align: center;
  position: relative;
  h1 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    color: ${props => props.theme.color.grey};
  }
  p {
    font-size: 1.2rem;
    font-family: ${props => props.theme.font.saturdayRock};
  }
  span {
    background-image: url(${underlineImg});
    background-repeat: no-repeat;
    background-position: 50%;
    height: 10px;
    display: block;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.6rem;
      position: absolute;
      left: 50%;
      transform: rotate(-2deg);
    }
    span {
      background-position: right;
    }
  }
`

const Content = styled.div`
  padding: 50px 5% 0 5%;
  text-align: center;
  p {
    padding-bottom: 30px;
    color: ${props => props.theme.color.dark};
    font-family: ${props => props.theme.font.galanoMed};
    &.italic {
      font-family: "galano_grotesquemedium_italic";
      color: ${props => props.theme.color.grey};
    }
    &.bold {
      padding: 0;
      font-family: ${props => props.theme.font.galanoBold};
    }
  }
  @media (min-width: 768px) {
    p {
      &.italic {
        &.small {
          padding: 0px 25% 30px 25%;
        }
      }
    }
  }
`
export default About
